import { Form, Input, Modal, Select, Space } from 'antd';
import { useState } from 'react';
import { useApi } from '../hooks/useApi';
interface AMSLinkModalProps {
  agencyId: string;
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}
enum AMSVendor {
  HAWKSOFT = 'HAWKSOFT',
  EPIC = 'EPIC',
  EPIC_SDK = 'EPIC_SDK',
  AMS360 = 'AMS360',
  IMAGERIGHT = 'IMAGERIGHT',
}

const vendorOptions = [
  { label: 'HawkSoft', value: AMSVendor.HAWKSOFT },
  { label: 'AMS360', value: AMSVendor.AMS360 },
  { label: 'ImageRight', value: AMSVendor.IMAGERIGHT },
  { label: 'Epic', value: AMSVendor.EPIC },
  { label: 'Epic SDK', value: AMSVendor.EPIC_SDK },
];

export const AMSLinkModal = ({ agencyId, visible, onClose, onSuccess }: AMSLinkModalProps) => {
  const [selectedVendor, setSelectedVendor] = useState<AMSVendor>();
  const {
    callApi: createAmsConfig,
    error,
    errorType,
    loading,
  } = useApi(`/agencies/${agencyId}/link-and-sync-AMS`, 'POST', false);
  const [form] = Form.useForm();

  if (error && errorType === useApi.errorTypes.CLIENT_ERROR) {
    console.log(error);
    form.setFields([
      {
        name: 'amsId',
        errors: ['invalid credentials, please check your credentials and try again'],
      },
    ]);
  }
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await createAmsConfig({ data: { ...values } });
      onSuccess();
      onClose();
      form.resetFields();
      setSelectedVendor(undefined);
    } catch (error) {
      console.error('Failed to link AMS:', error);
    }
  };

  const renderVendorFields = () => {
    switch (selectedVendor) {
      case AMSVendor.EPIC:
        return (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Form.Item name="userId" label="User ID" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="password" label="Password" rules={[{ required: true }]}>
              <Input.Password />
            </Form.Item>
            <Form.Item name="amsId" label="Subdomain" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="epicInstanceName" label="Instance Name">
              <Input />
            </Form.Item>
          </Space>
        );
      case AMSVendor.EPIC_SDK:
        return (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Form.Item name="epicSdkUrl" label="URL" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="amsId" label="Instance Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="password" label="Password" rules={[{ required: true }]}>
              <Input.Password />
            </Form.Item>
          </Space>
        );
      case AMSVendor.HAWKSOFT:
        return (
          <Form.Item name="amsId" label="Agency ID (4-5 digits)" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        );

      case AMSVendor.AMS360:
      case AMSVendor.IMAGERIGHT:
        return (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Form.Item name="amsId" label="Agency ID (XXXXXXX-1)" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="ams360AppAccessToAgencyKey"
              label="App Access Key"
              rules={[
                { required: true },
                {
                  pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                  message: 'Please ensure you have copied the entire key, the last section is 12 characters',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Space>
        );

      default:
        return null;
    }
  };

  return (
    <Modal
      title="Link Management System"
      visible={visible}
      onCancel={onClose}
      onOk={handleSubmit}
      okText="Link"
      confirmLoading={loading}
      width={500}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="managementSystemId" label="Management System" rules={[{ required: true }]}>
          <Select
            options={vendorOptions}
            value={selectedVendor}
            onChange={(value) => {
              setSelectedVendor(value);
              // reset every field except for managementSystemId
              form.resetFields([
                'ams360AppAccessToAgencyKey',
                'amsId',
                'apiKey',
                'epicInstanceName',
                'instanceName',
                'password',
                'url',
                'userId',
                'username',
              ]);
            }}
            placeholder="Select a management system"
          />
        </Form.Item>
        {renderVendorFields()}
      </Form>
    </Modal>
  );
};
