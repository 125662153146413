import { Button, Dropdown, Form, Input, Menu, Modal, PageHeader, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import { useState } from 'react';
import { useAuthContext } from '../components/AuthProvider';
import { DeactivateLoginModal } from '../components/DeactivateLoginModal';
import { MFAModal } from '../components/MFAModal';
import { SecurityQuestionModal } from '../components/SecurityQuestionModal';
import { UpdatePortalPasswordModal } from '../components/UpdatePortalPasswordModal';
import { UpdateUsernameModal } from '../components/UpdateUsernameModal';
import { useApi } from '../hooks/useApi';
import { Agency, CarrierLogin } from '../types';

export const CarriersPage = () => {
  const { callApi: getLogins, data, loading } = useApi('/portal-logins', 'GET', true);
  const {
    callApi: addLogin,
    loading: createLoading,
    error: createError,
    errorType: createErrorType,
  } = useApi('/portal-logins', 'POST', false);
  const { data: agencyResponse, loading: agencyLoading } = useApi('/agencies', 'GET', true);
  const agencies = get(agencyResponse, 'data', []) as Agency[];
  const [modalVisible, setModalVisible] = useState(false);
  const { portalSlugs, additionalLoginFields } = useAuthContext();
  const [selectedCarrierSlug, setSelectedCarrierSlug] = useState('');

  const columns: ColumnsType<CarrierLogin> = [
    { title: 'Id', dataIndex: 'id' },
    { title: 'Carrier', dataIndex: 'portalSlug' },
    { title: 'Agency', dataIndex: ['agency', 'name'] },
    { title: 'Username', dataIndex: 'username' },
    { title: 'Status', dataIndex: 'status' },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        let mainAction = null;
        if (record.status === 'MFA_CHECK') {
          mainAction = record.canFullyAutomateMfa ? (
            <Tooltip title="No need to fix manually, this will be updated automatically">
              <Button disabled={true}>Fix</Button>
            </Tooltip>
          ) : (
            <MFAModal login={record} reloadLogins={getLogins} />
          );
        } else if (record.status === 'SECURITY_QUESTION_CHECK') {
          mainAction = <SecurityQuestionModal login={record} reloadLogins={getLogins} />;
        } else if (record.status === 'CRED_ERROR' || record.status === 'PASSWORD_EXPIRED') {
          mainAction = (
            <UpdatePortalPasswordModal
              id={record.id}
              carrier={record.portalSlug}
              reloadLogins={getLogins}
              status={record.status}
            />
          );
        }

        const menu = (
          <Menu>
            <UpdatePortalPasswordModal
              id={record.id}
              carrier={record.portalSlug}
              reloadLogins={getLogins}
              status={record.status}
              trigger={<Menu.Item key="updatePassword">Update Password</Menu.Item>}
            />
            <UpdateUsernameModal
              id={record.id}
              carrier={record.portalSlug}
              currentUsername={record.username}
              reloadLogins={getLogins}
              trigger={<Menu.Item key="updateUsername">Update Username</Menu.Item>}
            />
            <DeactivateLoginModal
              id={record.id}
              carrier={record.portalSlug}
              reloadLogins={getLogins}
              trigger={<Menu.Item key="deactivate">Deactivate Login</Menu.Item>}
            />
          </Menu>
        );

        return (
          <div style={{ display: 'flex', gap: '8px' }}>
            {mainAction}
            <Dropdown overlay={menu} trigger={['click']}>
              <Button>More Actions ▼</Button>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const items = data as unknown as { data: CarrierLogin[] };

  const [form] = Form.useForm();
  if (createError && createErrorType === useApi.errorTypes.CLIENT_ERROR) {
    form.setFields([
      {
        name: 'password',
        errors: ['invalid credentials'],
      },
      {
        name: 'username',
        errors: ['invalid credentials'],
      },
    ]);
  }

  return (
    <PageHeader
      title="Linked Carriers"
      extra={[
        <Button
          type="primary"
          key="add"
          onClick={() => {
            setModalVisible(true);
          }}
        >
          Add
        </Button>,
      ]}
    >
      <Table
        dataSource={get(items, 'data', []).filter((login) => login.status !== 'INACTIVE')}
        loading={loading}
        columns={columns}
        rowKey="id"
      ></Table>
      <Modal
        title="Add Carrier"
        visible={modalVisible}
        confirmLoading={createLoading}
        onCancel={() => setModalVisible(false)}
        onOk={async () => {
          const formData = await form.validateFields();
          await addLogin({ data: formData });
          await getLogins();
          form.resetFields();
          setModalVisible(false);
        }}
      >
        {!agencyLoading && (
          // Hacky way to deal with options loaded from the db, will break if the agencies endpoint does
          <Form form={form} initialValues={{ agencyId: agencies[0].id }}>
            <Form.Item label="Agency" name="agencyId" rules={[{ required: true }]} hidden={agencies.length === 1}>
              <Select
                options={agencies.map((agency) => ({
                  label: agency.name,
                  value: agency.id,
                }))}
              />
            </Form.Item>
            <Form.Item
              label="Carrier Portal"
              name="carrierSlug"
              rules={[{ required: true, message: 'Please select a portal' }]}
            >
              <Select
                showSearch
                options={portalSlugs.sort().map((slug) => ({ label: slug, value: slug }))}
                onChange={(value) => {
                  setSelectedCarrierSlug(value as string);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              {/* This is a hack to prevent the password from being saved by the browser */}
              <Input.Password autoComplete="one-time-code" />
            </Form.Item>
            {Object.entries(additionalLoginFields[selectedCarrierSlug] || {}).map(([fieldName]) => (
              <Form.Item
                key={fieldName}
                label={fieldName}
                name={['additionalLoginFields', fieldName]}
                rules={[{ required: true, message: `Please input ${fieldName}!` }]}
              >
                <Input />
              </Form.Item>
            ))}
          </Form>
        )}
      </Modal>
    </PageHeader>
  );
};
