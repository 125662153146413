import { CopyOutlined, DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Descriptions, Divider, Form, Input, List, Modal, Popover, Select, Space, Spin } from 'antd';
import { get, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { AMSLinkModal } from '../components/AMSLinkModal';
import { useAuthContext } from '../components/AuthProvider';
import { useApi } from '../hooks/useApi';
import { Agency } from '../types';

type User = {
  publicApiKey: string;
  secretApiKey: string;
  testSecretApiKey: string;
  referralCode: string;
};

const referralProgramContent = (
  <div>
    <span>Each agency you refer to Adapt gets a free month.</span>
    <br />
    <span>If they sign on as a customer at the end of that month</span>
    <br />
    <span>you'll get a free month too!</span>
  </div>
);

export const SettingsPage = () => {
  const { data, loading } = useApi('/users/profile', 'GET', true);
  const { data: agenciesData, callApi: fetchAgencies } = useApi<{ data: Agency[] }>('/agencies', 'GET', true);
  const { isMultiAgency } = useAuthContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAgencyId, setSelectedAgencyId] = useState<string>();
  const [form] = Form.useForm();
  const [amsModalVisible, setAmsModalVisible] = useState(false);

  const agencies = get(agenciesData, 'data') || [];

  // Set initial selected agency when data loads
  useEffect(() => {
    if (agencies.length > 0 && !selectedAgencyId) {
      setSelectedAgencyId(agencies[0].id);
    }
    // The intent is to only run this hook after agencies are initially loaded
  }, [agencies[0]?.id]);

  const selectedAgency = agencies.find((agency) => agency.id === selectedAgencyId);
  const linkedInboxes = selectedAgency?.linkedInboxes;
  const notificationEmails = selectedAgency?.notificationEmails || [];

  const { callApi: addEmail } = useApi(`/agencies/${selectedAgencyId}/add-notification-email`, 'PUT', false);
  const { callApi: removeEmail } = useApi(`/agencies/${selectedAgencyId}/remove-notification-email`, 'PUT', false);

  const handleAddEmail = async (values: { email: string }) => {
    try {
      await addEmail({ data: { email: values.email } });
      await fetchAgencies(); // Refresh agencies data
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Failed to add email:', error);
    }
  };

  const handleRemoveEmail = async (email: string) => {
    Modal.confirm({
      title: 'Remove Notification Email',
      content: `Are you sure you want to remove ${email}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await removeEmail({ data: { email } });
          await fetchAgencies(); // Refresh agencies data
        } catch (error) {
          console.error('Failed to remove email:', error);
        }
      },
    });
  };

  if (loading) return <Spin />;
  const { secretApiKey, testSecretApiKey, referralCode } = data as User;
  const referralLink = `${window.location.origin}/signup?referralCode=${referralCode}`;

  return (
    <Space direction="vertical">
      <Descriptions
        labelStyle={{ alignItems: 'left' }}
        column={1}
        title="General Settings"
        style={{ textAlign: 'left' }}
      >
        <Descriptions.Item
          label={
            <Popover content={referralProgramContent} title="Adapt Referral Program" placement="topRight">
              <span>Referral link </span>
              <InfoCircleOutlined />
            </Popover>
          }
        >
          <Input
            readOnly
            value={referralLink}
            addonAfter={<CopyOutlined onClick={() => navigator.clipboard.writeText(referralLink)} />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Test Secret Key">
          <Input
            readOnly
            value={testSecretApiKey}
            type="password"
            addonAfter={<CopyOutlined onClick={() => navigator.clipboard.writeText(testSecretApiKey)} />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Live Secret Key">
          <Input
            readOnly
            value={secretApiKey}
            type="password"
            addonAfter={<CopyOutlined onClick={() => navigator.clipboard.writeText(secretApiKey)} />}
          />
        </Descriptions.Item>
      </Descriptions>

      <Divider style={{ margin: '12px 0' }} />

      <Descriptions
        labelStyle={{ alignItems: 'center' }}
        column={1}
        title={
          <Space>
            Agency Settings
            {isMultiAgency && agencies.length > 1 && (
              <Select
                value={selectedAgencyId}
                onChange={setSelectedAgencyId}
                style={{ width: 200, marginLeft: 16 }}
                options={agencies.map((agency) => ({
                  label: agency.name,
                  value: agency.id,
                }))}
              />
            )}
          </Space>
        }
        style={{ textAlign: 'left' }}
      >
        {!isMultiAgency && selectedAgency && (
          <Descriptions.Item label="Management System">
            <Space>
              {selectedAgency.amsLink && (
                <span>{startCase(selectedAgency.amsLink.managementSystemId.toLowerCase())} linked </span>
              )}
              <Button onClick={() => setAmsModalVisible(true)}>
                {selectedAgency.amsLink ? 'Update' : 'Link'} Management System
              </Button>
              <AMSLinkModal
                agencyId={selectedAgency.id}
                visible={amsModalVisible}
                onClose={() => setAmsModalVisible(false)}
                onSuccess={fetchAgencies}
              />
            </Space>
          </Descriptions.Item>
        )}
        {selectedAgency && (
          <>
            <Descriptions.Item label="Notification Emails">
              <Space direction="vertical" style={{ width: '100%' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', alignItems: 'center' }}>
                  <span
                    key={selectedAgency.email}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      backgroundColor: '#f0f0f0',
                      padding: '4px 8px',
                      borderRadius: '4px',
                    }}
                  >
                    {selectedAgency.email}
                  </span>
                  {notificationEmails.map((email) => (
                    <span
                      key={email}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        backgroundColor: '#f0f0f0',
                        padding: '4px 8px',
                        borderRadius: '4px',
                      }}
                    >
                      {email}
                      <DeleteOutlined
                        onClick={() => handleRemoveEmail(email)}
                        style={{ marginLeft: '8px', color: 'red', cursor: 'pointer' }}
                      />
                    </span>
                  ))}
                  <Button type="dashed" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)} size="small">
                    Add Email
                  </Button>
                </div>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="Linked Mailboxes">
              <Space direction="horizontal">
                {linkedInboxes && (
                  <List dataSource={linkedInboxes} renderItem={(item) => <List.Item>{item.email}</List.Item>} />
                )}
                <a href={selectedAgency.connectOutlookLink} target="_blank" rel="noopener noreferrer">
                  Add Outlook Mailbox
                </a>
              </Space>
            </Descriptions.Item>
          </>
        )}
      </Descriptions>

      <Modal
        title="Add Notification Email"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddEmail}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input an email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input placeholder="Enter email address" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Email
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Space>
  );
};
