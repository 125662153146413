import { Modal } from 'antd';
import { ReactElement } from 'react';
import { useApi } from '../hooks/useApi';

interface DeactivateLoginModalProps {
  id: string;
  carrier: string;
  reloadLogins: () => Promise<void>;
  trigger: ReactElement;
}

export const DeactivateLoginModal = ({ id, carrier, reloadLogins, trigger }: DeactivateLoginModalProps) => {
  const { callApi: deactivateLogin, loading } = useApi(`/portal-logins/${id}/deactivate`, 'PUT', false);

  const handleDeactivate = () => {
    Modal.confirm({
      title: 'Deactivate Login',
      content: `Are you sure you want to deactivate this login for ${carrier}?  You will no longer receive notifications or documents for this login`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      okButtonProps: {
        loading,
      },
      onOk: async () => {
        await deactivateLogin();
        await reloadLogins();
      },
    });
  };

  return <div onClick={handleDeactivate}>{trigger}</div>;
};
