import { Button, Form, Input, Modal } from 'antd';
import { ReactElement, useState } from 'react';
import { useApi } from '../hooks/useApi';

interface UpdateUsernameModalProps {
  id: string;
  carrier: string;
  currentUsername: string;
  reloadLogins: () => Promise<void>;
  trigger: ReactElement;
}

export const UpdateUsernameModal = ({
  id,
  carrier,
  currentUsername,
  reloadLogins,
  trigger,
}: UpdateUsernameModalProps) => {
  const [visible, setVisible] = useState(false);
  const { callApi: updateUsername, loading, error, errorType } = useApi(`/portal-logins/${id}`, 'PUT', false);
  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    await updateUsername({ data: { username: values.username, password: values.password } });
    await reloadLogins();
    setVisible(false);
    form.resetFields();
  };

  if (error && errorType === useApi.errorTypes.CLIENT_ERROR) {
    form.setFields([
      {
        name: 'username',
        errors: ['invalid credentials'],
      },
      {
        name: 'password',
        errors: ['invalid credentials'],
      },
    ]);
  }

  return (
    <>
      <div onClick={() => setVisible(true)}>{trigger}</div>
      <Modal
        title={`Update Username - ${carrier}`}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
            Update
          </Button>,
        ]}
      >
        <Form form={form} onFinish={handleSubmit} initialValues={{ username: currentUsername }} layout="vertical">
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: 'Please input the new username!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password autoComplete="current-password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
